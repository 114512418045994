import React, { useState } from 'react';
import Recaptcha from 'react-recaptcha';
import { useFormik } from 'formik'
import useSSR from 'use-ssr'
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { LgTitle, SmTitle } from '../elements/Titles';
import { XsParagraph, BaseParagraph, SmParagraph } from '../elements/Paragraphs';
import '../styles/work-with-us.css'
const WorkWithUs = () => {
  const [isSubmitted, setSubmitted] = useState(false);
  const { isBrowser } = useSSR();
  const [submissionMessage, setSubmissionMessage] = useState('');
  const success = () => {
    setSubmitted(true);
    setSubmissionMessage("Your request was successfully submitted. We'll get back to you shortly!")
    trackFormSubmission('successful', 1);
    setTimeout(() => {
      setSubmitted(false);
      setSubmissionMessage('');
    }, 3000);
  }
  const fail = () => {
    setSubmitted(true);
    setSubmissionMessage('Something went wrong! Please try again in a moment!')
    trackFormSubmission('failed', 0);
    setTimeout(() => {
      setSubmitted(false);
      setSubmissionMessage('');
    }, 3000);
  }
  const validate = values => {
    const errors = {};

    if(!values.firstName){
      errors.firstName = 'This field is required';
    }
    if(!values.lastName){
      errors.lastName = 'This field is required';
    }
    if(!values.company){
      errors.company = 'This field is required';
    }
    if(!values.goals){
      errors.goals = 'This field is required';
    }
    if(!values.email){
      errors.email = 'This field is required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
      errors.email = "Please enter a valid email address";
    }

    return errors;
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      goals: '',
      email: '',
      company: '',
      captchaResponse: '',
    },
    validate,
    onSubmit: (values, {resetForm, setSubmitting,}) => {
      fetch('https://moneywise.com/contact-us-mek/form/submit',{
        method: `POST`,
        body: JSON.stringify(values),
        headers: {
          "content-type" : 'application/json',
          'Accept': 'application/json',
          "Origin" : 'https://wisepublishing.com',
        }
      }
      )
      .then(res => res.json())
      .then(body => { 
        body === 200 ? success() : fail();
        setSubmitting(false);
        resetForm({});
      }).catch(error => {
        console.log(error)
      })
    }
  })


  // Function to track media-kit downloads

  const trackMediaKitSubmission = () => {
    typeof window !== "undefined" && window.gtag("event", "media_kit_download", { 
      'event_label': 'media_kit_download',
      'transport_type': 'beacon',
      value: 1
     })
  }

  const trackFormSubmission = (status, value) => {
    typeof window !== "undefined" && window.gtag("event", `contact_us_${status}_sumbission`, { 
      'event_label': 'contact_us_form_sumbission',
      'transport_type': 'beacon',
      value: value
     })
  }
  return (
    <Layout>
      <SEO title="Work with Us" />
      <Container>
        <Row>
          <Row marginBottom="24">
            <Column width="12/12">
                <BaseParagraph style={{paddingLeft: '1rem'}}>
                  Learn more about the opportunities to connect with our in-market audiences across North America. 
                  Take a deeper look at the digital strategy that connects savvy consumers with brands and products. 
                  Our media kit includes data on our extensive reach into valuable markets, advertising product details, content schedules and ad specs.
                </BaseParagraph>
              </Column>
            </Row>
            <Column width="8/12">
              <Row marginBottom="36">
                <Column width="12/12">
                  <LgTitle marginBottom="16">Contact Us</LgTitle>
                  <XsParagraph>Hello, why are you contacting us today?</XsParagraph>
                </Column>
              </Row>
              <Row> 
                <Column width="12/12">
                  <form 
                  onSubmit={formik.handleSubmit}
                  >
                    <Row>
                      <Column width="6/12">
                      <label>
                      First name<span>*</span>
                          <input 
                          type="text" 
                          id="firstName"
                          name="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          />
                          {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="error-message">{formik.errors.firstName}</div>
                          ) : null}
                        </label>
                      </Column>
                      <Column width="6/12">
                        <label>
                        Last name<span>*</span>
                          <input 
                          type="text" 
                          id="lastName"
                          name="lastName"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="error-message">{formik.errors.lastName}</div>
                          ) : null}
                        </label>
                      </Column>
                    </Row>
                    <Row>
                      <Column width="12/12">
                      <label>
                          Email address<span>*</span>
                          <input 
                          type="text" 
                          id="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          />
                          {formik.touched.email && formik.errors.email ? (
                          <div className="error-message">{formik.errors.email}</div>
                          ) : null}
                        </label>
                      </Column>
                    </Row>
                    <Row>
                      <Column width="12/12">
                      <label>
                          Your company<span>*</span>
                          <input 
                          type="text" 
                          id="company"
                          name="company"
                          value={formik.values.company}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          />
                          {formik.touched.company && formik.errors.company ? (
                          <div className="error-message">{formik.errors.company}</div>
                          ) : null}
                        </label>
                      </Column>
                    </Row>
                    <Row>
                      <Column width="12/12">
                        <label>
                        How can we help?<span>*</span>
                          <textarea 
                          id="goals"
                          name="goals"
                          value={formik.values.goals}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          />
                          {formik.touched.goals && formik.errors.goals ? (
                          <div className="error-message">{formik.errors.goals}</div>
                          ) : null}
                        </label>
                      </Column>
                    </Row>
                    <Row>
                      <Column width="12/12">
                        {isBrowser && 
                          <Recaptcha 
                          sitekey="6LeIHTYbAAAAAPpMSscEiMDbHimO41mjLewvfSFQ"
                          verifyCallback = { (resposne) => formik.setFieldValue('captchaResponse', resposne) }
                          />
                        }
                      </Column>
                    </Row>
                    <Row>
                      <Column width="4/12">
                        <input type="submit" className="btn" disabled={formik.isSubmitting}></input>
                        <XsParagraph className={`submit-message ${isSubmitted ? '' : 'submit-hidden'}`}>{submissionMessage}</XsParagraph>
                      </Column>
                    </Row>
                  </form>
                </Column>
              </Row>
            </Column>
            <Column width="4/12">
                <Row style={{marginLeft: '.5rem'}}><a href ="https://media1.wisepublishing.com/media-kit/2024-moneywise-media-kit.pdf" target="_blank" onClick={trackMediaKitSubmission}><img src="https://media1.wisepublishing.com/media-kit/2024-mediakit-image.png" alt="" /></a></Row>
                <Row style={{marginLeft: '.5rem'}}><XsParagraph>Click below to download our 2024 Media Kit</XsParagraph></Row>
                <Row style={{marginLeft: '.5rem'}}><a href="https://media1.wisepublishing.com/media-kit/2024-moneywise-media-kit.pdf" target="_blank" className="btn" onClick={trackMediaKitSubmission}>Download</a></Row>
                <Row style={{marginLeft: '.5rem'}}><SmTitle style={{color: 'rgb(86, 7, 100)', marginBottom: '0', marginTop: '2rem'}}>Connect with our Team</SmTitle></Row>
                <Row style={{marginLeft: '.5rem', marginBottom: '0'}}><XsParagraph>Angus Baldocchi</XsParagraph></Row>
                <Row style={{marginLeft: '.5rem', marginBottom: '0'}}><XsParagraph>Senior Commercial Partnerships Director</XsParagraph></Row>
                <Row style={{marginLeft: '.5rem'}}><a href='mailto&#58;%&#54;&#49;ng&#37;75s%2Eba%6C&#100;occhi&#64;wi%73&#101;&#37;7&#48;ub%6C&#105;&#115;h&#37;6&#57;%6Eg%&#50;&#69;%6&#51;om'>ang&#117;s&#46;b&#97;&#108;docchi&#64;&#119;isepublis&#104;&#105;ng&#46;co&#109;</a></Row>
                <Row style={{marginLeft: '.5rem', marginBottom: '0'}}><XsParagraph>Aaron Young</XsParagraph></Row>
                <Row style={{marginLeft: '.5rem', marginBottom: '0'}}><XsParagraph>Senior Director of Business Development</XsParagraph></Row>
                <Row style={{marginLeft: '.5rem'}}><a href='mailt&#111;&#58;aa&#114;o%6E%2E&#121;oung&#64;wisepu%62%&#54;C&#105;s&#37;&#54;8ing&#46;co&#109;'>aaron&#46;yo&#117;n&#103;&#64;&#119;i&#115;ep&#117;bli&#115;hing&#46;com</a></Row>
                <Row style={{marginLeft: '.5rem', marginBottom: '0'}}><XsParagraph>Jason Lendrum</XsParagraph></Row>
                <Row style={{marginLeft: '.5rem', marginBottom: '0'}}><XsParagraph>Partnerships Manager</XsParagraph></Row>
                <Row style={{marginLeft: '.5rem'}}><a href='mailto:jason.lendrum@wisepublishing.com'>jason.lendrum@wisepublishing.com</a></Row>
            </Column>
        </Row>

      </Container>
    </Layout>
  );
};

export default WorkWithUs;
